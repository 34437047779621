<template>
  <div class="member">
    <div class="bg max-w1200 mrauto mt47 mb51 pd20">
      <div class="flex pd40 bg-ffffff bor-E6E6E6">
        <div>
          <el-menu
            :default-active="navIndex"
            class="el-menu-vertical-demo"
            background-color="#F1F1F1"
            text-color="#222222"
            active-text-color="#CC0000"
            @select="select"
          >
            <el-menu-item index="1">
              <span slot="title">会员信息</span>
            </el-menu-item>
            <el-menu-item index="2">
              <span slot="title">我的发布</span>
            </el-menu-item>
            <el-menu-item index="3">
              <span slot="title">我的接店</span>
            </el-menu-item>
            <el-menu-item index="4">
              <span slot="title">我的收藏</span>
            </el-menu-item>
            <el-menu-item index="5" v-if="Info.user_type > 0">
              <span slot="title">申请商家入驻</span>
            </el-menu-item>
            <el-menu-item index="6" v-if="Info.user_type > 0">
              <span slot="title">申请城市合伙人</span>
            </el-menu-item>
            <el-menu-item index="7" v-if="Info.user_type > 0">
              <span slot="title">申请城市代理</span>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="pd40 per-w100">
          <!-- 会员信息 -->
          <div v-if="navIndex == 1">
            <el-form label-width="5rem" label-position="right">
              <el-form-item label="姓名：">
                <el-input v-model="Info.nickname" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="电话：">
                <el-input v-model="Info.x_phone" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="等级：">
                <el-input v-model="Info.user_type_name" :disabled="true"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <!-- 发布 -->
          <div v-if="navIndex == 2">
            <div class="ovflow-w" :class="isMobile()?'per-w80':'w847'">
              <div class="flex a-center">
                <div
                  class="h60 min-w140 cup flex a-center j-center fs-18 fwb"
                  :class="item.id == type ? 'bg-d43030 col-ffffff' : 'col-383838'"
                  v-for="(item, index) in typeList"
                  :key="index"
                  @click="WinportClick(item.id)"
                >
                  {{ item.name }}
                </div>
              </div>
              <!-- <div>
                <el-button type="primary" size="small" @click="release"
                  >发布信息</el-button
                >
              </div> -->
            </div>
            <div :class="isMobile()?'per-w80':'w847'" v-if="type != 3">
              <ul class="pt10">
                <li
                  v-for="(item, index) in list"
                  :key="index"
                  class="pb22 pt17 borb-DADADA"
                  @click="detailsCut3(item)"
                >
                  <div class="flex">
                    <div class="w182 h110 mr23 ycj por">
                      <img :src="item.cover" alt="" class="w182 h110" />
                      <div class="poa-lt" v-for="items in itemList" :key="items.label">
                        <el-tag
                          v-if="item.status == items.state"
                          :type="items.type"
                          effect="dark"
                        >
                          {{ items.label }}
                        </el-tag>
                      </div>
                      <div class="poa-rt" v-if="item.is_pay == 0">
                        <el-tag effect="dark"> 免费 </el-tag>
                      </div>
                      <div class="poa-rt" v-if="item.is_pay == 1">
                        <el-tag effect="dark"> 付费 </el-tag>
                      </div>
                    </div>
                    <div class="per-w100">
                      <div>
                        <div class="flex a-center fs-16 col-333333">
                          <div class="max-w372 fwb">
                            {{ item.title }}
                          </div>
                          <div class="w20h22 ml5 mr20">
                            <img src="../../assets/img/hudun.png" alt="" class="w20h22" />
                          </div>
                          <div
                            class="w46h24 flex a-center j-center br100 bg-3FBCEF fs-12 col-ffffff mr10"
                          >
                            多图
                          </div>

                          <div
                            class="w46h24 flex a-center j-center br100 bg-19be6b fs-12 col-ffffff"
                            v-if="item.ad_loc == 2"
                          >
                            优选
                          </div>
                          <div
                            class="w46h24 flex a-center j-center br100 bg-FE8D55 fs-12 col-ffffff"
                            v-if="item.ad_loc == 1"
                          >
                            头版
                          </div>
                          <!-- <div class="w24h24" v-if="item.ad_loc == 2">
                            <img src="../../assets/img/you.png" alt="" class="w24h24" />
                          </div>
                          <div class="w24h24" v-if="item.ad_loc == 1">
                            <img
                              src="../../assets/img/touban.png"
                              alt=""
                              class="w24h24"
                            />
                          </div> -->
                        </div>
                      </div>
                      <div class="flex j-sb ml10 mt23">
                        <div></div>
                        <div>
                          <el-button
                            size="mini"
                            @click="kaiqishop(item)"
                            v-if="item.ref_expire == 2 || item.ad_expire == 2"
                            >开启
                          </el-button>
                          <el-button
                            size="mini"
                            @click.stop="shangjiashop(item)"
                            v-if="item.status == 10 && item.is_refund_bond != 2"
                            >上架
                          </el-button>
                          <el-button
                            size="mini"
                            v-if="item.status != 10"
                            @click.stop="xiajia(item)"
                            >下架
                          </el-button>
                          <el-button
                            type="primary"
                            size="mini"
                            v-if="item.status != 3 && item.is_refund_bond != 2"
                            @click.stop="xiugai(item)"
                            >修改
                          </el-button>
                          <el-button
                            type="success"
                            size="mini"
                            v-if="item.status == 2"
                            @click.stop="Confirmtr(item.id, item.type)"
                            >确认转</el-button
                          >
                          <el-button
                            type="warning"
                            size="mini"
                            @click.stop="CancelRelease(item.id, item.type)"
                            >取消发布
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul class="pt10">
                <li
                  v-for="(item, index) in list"
                  :key="index"
                  class="pb22 pt17 borb-DADADA"
                  @click="detailsCut3(item)"
                >
                  <div class="flex a-center j-sb per-w100">
                    <div class="per-w50 flex a-center">
                      <div>
                        <div class="flex a-center fs-16 col-333333">
                          <div class="max-w372 fwb">
                            {{ item.title }}
                          </div>
                          <div class="ml5 w20h22 mr10">
                            <img src="../../assets/img/hudun.png" alt="" class="w20h22" />
                          </div>
                          <div class="w24h24" v-if="item.ad_loc == 2">
                            <img src="../../assets/img/you.png" alt="" class="w24h24" />
                          </div>
                          <div class="w24h24" v-if="item.ad_loc == 1">
                            <img
                              src="../../assets/img/touban.png"
                              alt=""
                              class="w24h24"
                            />
                          </div>
                        </div>
                        <div class="flex a-center mt23 fs-12 col-666666 f-w">
                          求租行业：{{ item.classify_name }}
                        </div>
                        <!-- <div class="mt5 fs-12 col-666666">
                          求租区域：{{ item.expect_area }}
                        </div> -->
                      </div>
                    </div>
                    <div class="per-w30 flex a-center j-center fs-12 col-333333">
                      <div>
                        <div>
                          <span class="fs-24 col-FF0000">{{ item.area_min }} </span>
                          <span v-if="item.area_unit == 1">平方米</span>
                          <span v-if="item.area_unit == 2">亩</span>
                        </div>
                        <div class="mt5 fs-12 col-666666 text-center">求租面积</div>
                      </div>
                    </div>
                    <div
                      class="per-w20 flex a-center j-center col-333333 fs-16 text-center"
                    >
                      <div>
                        <div>
                          <div
                            v-if="item.rent_price_list == 0"
                            class="fs-16 col-333333 fwb"
                          >
                            面议
                          </div>
                          <div v-if="item.rent_price_list == 1">
                            <span>{{ item.rent_min }}~{{ item.rent_max }}元</span>/
                            <span v-if="item.rent_type == 1">年</span>
                            <span v-if="item.rent_type == 2">月</span>
                            <span v-if="item.rent_type == 3">平方</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex j-sb ml10 mt23">
                    <div></div>
                    <div>
                      <el-button
                        size="mini"
                        @click="kaiqishop(item)"
                        v-if="item.ref_expire == 2 || item.ad_expire == 2"
                        >开启
                      </el-button>
                      <el-button
                        size="mini"
                        @click.stop="shangjiashop(item)"
                        v-if="item.status == 10 && item.is_refund_bond != 2"
                        >上架
                      </el-button>
                      <el-button
                        size="mini"
                        v-if="item.status != 10"
                        @click.stop="xiajia(item)"
                        >下架
                      </el-button>
                      <el-button
                        type="primary"
                        size="mini"
                        v-if="item.status != 3 && item.is_refund_bond != 2"
                        @click.stop="xiugai(item)"
                        >修改
                      </el-button>
                      <el-button
                        type="warning"
                        size="mini"
                        @click.stop="CancelRelease(item.id, item.type)"
                        >取消发布</el-button
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 接店 -->
          <div v-if="navIndex == 3">
            <div>
              <ul class="pt10">
                <li
                  v-for="(item, index) in list"
                  :key="index"
                  class="pb22 pt17 borb-DADADA"
                  @click="detailsCut(item)"
                >
                  <div class="flex">
                    <div class="w182 h110 mr23 ycj">
                      <img :src="item.cover" alt="" class="w182 h110" />
                    </div>
                    <div class="per-w100">
                      <div>
                        <div class="flex a-center fs-16 col-333333">
                          <div class="max-w372 fwb">
                            {{ item.title }}
                          </div>
                          <div class="w20h22 ml5 mr20">
                            <img src="../../assets/img/hudun.png" alt="" class="w20h22" />
                          </div>
                          <div
                            class="ptb4lr11 flex a-center j-center br100 bg-3FBCEF fs-12 col-ffffff mr10"
                          >
                            多图
                          </div>

                          <div
                            class="w46h24 flex a-center j-center br100 bg-19be6b fs-12 col-ffffff"
                            v-if="item.ad_loc == 2"
                          >
                            优选
                          </div>
                          <div
                            class="w46h24 flex a-center j-center br100 bg-FE8D55 fs-12 col-ffffff"
                            v-if="item.ad_loc == 1"
                          >
                            头版
                          </div>
                          <!-- <div class="w24h24" v-if="item.ad_loc == 2">
                            <img src="../../assets/img/you.png" alt="" class="w24h24" />
                          </div>
                          <div class="w24h24" v-if="item.ad_loc == 1">
                            <img
                              src="../../assets/img/touban.png"
                              alt=""
                              class="w24h24"
                            />
                          </div> -->
                        </div>
                      </div>
                      <div class="flex j-sb ml10 mt23">
                        <div></div>
                        <div>
                          <el-button
                            type="primary"
                            size="mini"
                            v-if="item.status != 3"
                            @click.stop="CancelShop(item.id, item.type)"
                            >取消接店</el-button
                          >
                          <!-- <el-button type="warning" size="mini">申请商家入驻</el-button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 收藏 -->
          <div v-if="navIndex == 4">
            <div>
              <ul class="pt10">
                <li
                  v-for="(item, index) in list"
                  :key="index"
                  class="pb22 pt17 borb-DADADA"
                  @click="detailsCut2(item)"
                >
                  <div class="flex per-w100">
                    <div class="w182 h110 mr23 ycj">
                      <img :src="item.cover" alt="" class="w182 h110" />
                    </div>
                    <div class="per-w100">
                      <div>
                        <div class="flex a-center fs-16 col-333333">
                          <div class="max-w372 fwb">
                            {{ item.title }}
                          </div>
                          <div class="w20h22 ml5 mr20">
                            <img src="../../assets/img/hudun.png" alt="" class="w20h22" />
                          </div>
                          <div
                            class="ptb4lr11 flex a-center j-center br100 bg-3FBCEF fs-12 col-ffffff mr10"
                          >
                            多图
                          </div>

                          <div
                            class="w46h24 flex a-center j-center br100 bg-19be6b fs-12 col-ffffff"
                            v-if="item.ad_loc == 2"
                          >
                            优选
                          </div>
                          <div
                            class="w46h24 flex a-center j-center br100 bg-FE8D55 fs-12 col-ffffff"
                            v-if="item.ad_loc == 1"
                          >
                            头版
                          </div>
                          <!-- <div class="w24h24" v-if="item.ad_loc == 2">
                            <img src="../../assets/img/you.png" alt="" class="w24h24" />
                          </div>
                          <div class="w24h24" v-if="item.ad_loc == 1">
                            <img
                              src="../../assets/img/touban.png"
                              alt=""
                              class="w24h24"
                            />
                          </div> -->
                        </div>
                      </div>
                      <div class="flex j-sb ml10 mt23">
                        <div></div>
                        <div>
                          <el-button
                            type="warning"
                            size="mini"
                            @click.stop="CancelCollect(item.shop_id, item.type)"
                            >取消收藏
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 申请商家入驻 -->
          <div v-if="navIndex == 5">
            <div class="flex j-sb mb15">
              <div></div>
              <div>
                <el-button type="primary" size="small" @click="cutbusiness()">
                  申请入驻
                </el-button>
              </div>
            </div>
            <div class="">
              <ul v-if="list && list.length">
                <li
                  v-for="(item, index) in list"
                  :key="index"
                  class="pb22 pt17 borb-DADADA"
                >
                  <div class="flex per-w100">
                    <div class="w182 h110 mr23 ycj">
                      <img :src="item.logo" alt="" class="w182 h110" />
                    </div>
                    <div class="per-w100 flex j-sb">
                      <div class="flex j-sb per-w100 mr20">
                        <div class="fs-20 col-333333 mr20">
                          {{ item.store_name }}
                        </div>
                        <div class="fs-16 col-333333 mr20">
                          <div>
                            店铺类型：
                            <span class="col-CC0000 fs-18">{{
                              item.store_type_name
                            }}</span>
                          </div>
                          <div class="fs-12 mt20 col-999999">
                            类别： {{ item.platform }}
                          </div>
                        </div>
                        <div v-if="item.status == 3" class="fs-14 col-666666">
                          驳回原因：{{ item.reject_text }}
                        </div>
                        <div v-else></div>
                      </div>
                      <div class="flex a-center">
                        <el-button
                          type="warning"
                          size="mini"
                          @click="cutbusiness(item.id, item.status)"
                          v-if="item.status == 1"
                        >
                          {{ item.status_text }}
                        </el-button>
                        <el-button
                          type="warning"
                          size="mini"
                          @click="cutbusiness(item.id, item.status)"
                          v-if="item.status == 2"
                        >
                          {{ item.status_text }}
                        </el-button>
                        <el-button
                          type="warning"
                          size="mini"
                          @click="cutbusiness(item.id, item.status)"
                          v-if="item.status == 3"
                        >
                          {{ item.status_text }},重新申请入驻
                        </el-button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div v-else>
                <img
                  src="https://pt.baipubang.com/app_icon/my/merchant-bg.png"
                  alt=""
                  class="mrauto"
                  style="display: inherit"
                />
              </div>
            </div>
          </div>
          <!-- 申请城市合伙人 -->
          <div v-if="navIndex == 6">
            <div class="flex j-sb mb15">
              <div v-if="partnerInfo.apply_val.status == 3">
                驳回原因：{{ partnerInfo.apply_val.reject_text }}
              </div>
              <div v-else></div>
              <div>
                <el-button
                  type="primary"
                  size="small"
                  v-if="partnerInfo.apply_val.status == 1"
                  @click="cutpartner()"
                >
                  {{ partnerInfo.apply_val.status_text }}
                </el-button>
                <el-button
                  type="primary"
                  size="small"
                  v-else-if="partnerInfo.apply_val.status == 2"
                  @click="cutpartner()"
                >
                  {{ partnerInfo.apply_val.status_text }}
                </el-button>
                <el-button
                  type="primary"
                  size="small"
                  v-else-if="partnerInfo.apply_val.status == 3"
                  @click="cutpartner()"
                >
                  {{ partnerInfo.apply_val.status_text }},重新申请
                </el-button>
                <el-button type="primary" size="small" v-else @click="cutpartner()">
                  申请城市合伙人
                </el-button>
              </div>
            </div>
            <div v-html="partnerInfo.val"></div>
          </div>
          <!-- 申请城市代理 -->
          <div v-if="navIndex == 7">
            <div class="flex j-sb mb15">
              <div v-if="partnerInfo.apply_val.status == 3">
                驳回原因：{{ partnerInfo.apply_val.reject_text }}
              </div>
              <div v-else></div>
              <div>
                <el-button
                  type="primary"
                  size="small"
                  v-if="partnerInfo.apply_val.status == 1"
                  @click="cutagents()"
                >
                  {{ partnerInfo.apply_val.status_text }}
                </el-button>
                <el-button
                  type="primary"
                  size="small"
                  v-else-if="partnerInfo.apply_val.status == 2"
                  @click="cutagents()"
                >
                  {{ partnerInfo.apply_val.status_text }}
                </el-button>
                <el-button
                  type="primary"
                  size="small"
                  v-else-if="partnerInfo.apply_val.status == 3"
                  @click="cutagents()"
                >
                  {{ partnerInfo.apply_val.status_text }},重新申请
                </el-button>
                <el-button type="primary" size="small" v-else @click="cutagents()">
                  申请城市代理
                </el-button>
              </div>
            </div>
            <div v-html="partnerInfo.val"></div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="dateflag" width="30%">
        <div style="padding: 0.9375rem">
          <el-time-picker
            is-range
            v-model="time_frame"
            value-format="HH:mm"
            format="HH:mm"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          >
          </el-time-picker>
          <div
            style="
              padding: 0.625rem;
              text-align: center;
              background-color: #fe8d55;
              border-radius: 0.3125rem;
              color: #ffffff;
              margin-top: 0.625rem;
            "
            @click="quedingxiu"
          >
            修改
          </div>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="admendflag" width="30%">
        <div style="padding: 0.9375rem">
          <div
            style="
              padding: 0.625rem;
              text-align: center;
              background-color: #fe8d55;
              border-radius: 0.3125rem;
              color: #ffffff;
              margin-bottom: 0.625rem;
            "
            @click="admend"
          >
            内容修改
          </div>
          <div
            style="
              padding: 0.625rem;
              text-align: center;
              background: linear-gradient(-90deg, #fc725f, #ff2d21);
              border-radius: 0.3125rem;
              color: #ffffff;
            "
            v-if="this.item.type != 4 && this.item.type != 3"
            @click="goto_list"
          >
            刷新修改
          </div>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="suspendflag" width="30%">
        <div style="padding: 0.9375rem">
          <div
            style="
              padding: 0.625rem;
              text-align: center;
              background-color: #fe8d55;
              border-radius: 0.3125rem;
              color: #ffffff;
              margin-bottom: 0.625rem;
            "
            @click="zantingshop"
            v-if="
              (detaildata.ref_expire == 0 && detaildata.status != 3) ||
              (detaildata.ad_expire == 0 && detaildata.status != 3)
            "
          >
            暂停发布
          </div>
          <div
            style="
              padding: 0.625rem;
              text-align: center;
              background: linear-gradient(-90deg, #fc725f, #ff2d21);
              border-radius: 0.3125rem;
              color: #ffffff;
            "
            @click="xiajiashop"
          >
            下架发布
          </div>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="show" width="30%">
        <div class="wh400 mrauto">
          <img src="../../assets/img/xiaochegnxu.jpg" alt="" class="wh400" />
        </div>
      </el-dialog>
      <el-dialog title="接店列表" :visible.sync="dialogTableVisible">
        <el-table :data="gridData">
          <el-table-column property="id" label="id" width="200"></el-table-column>
          <el-table-column label="头像">
            <template slot-scope="scope">
              <div class="wh50">
                <img :src="scope.row.avatar" alt="" class="wh50" />
              </div>
            </template>
          </el-table-column>
          <el-table-column property="name" label="姓名"></el-table-column>
          <el-table-column property="phone" label="手机号"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                v-if="scope.row.is_to_uid !== 1"
                type="primary"
                >确认转</el-button
              >
              <el-button v-else size="mini" type="success">已成交</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  user_core,
  my_send,
  meet_shop_list,
  user_collect,
  been_the_store,
  transfer_out,
  cancel_send,
  cancel_take,
  del_collect,
  my_apply,
  city_partner,
  city_agents,
  ad_suspend,
  take_down,
  take_up,
  cancel_suspend,
  edit_ref_time_sub,
} from "../../utils/api.js";
export default {
  data() {
    return {
      dateflag: false,
      admendflag: false,
      detaildata: "",
      suspendflag: false,
      partnerInfo: "",
      gridData: [],
      show: false,
      dialogTableVisible: false,
      list: "",
      type: "",
      time_frame: "",
      itemList: [
        { type: "success", label: "发布中", state: 1 },
        { type: "success", label: "已接店", state: 2 },
        { type: "success", label: "已成交", state: 3 },
        { type: "danger", label: "已下架", state: 10 },
        { type: "warning", label: "草稿", state: 100 },
        { type: "warning", label: "被举报", state: 4 },
      ],
      typeList: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 1,
          name: "转让",
        },
        {
          id: 2,
          name: "出租",
        },
        {
          id: 3,
          name: "找店",
        },
        {
          id: 4,
          name: "招商",
        },
        {
          id: 5,
          name: "出售",
        },
        {
          id: 6,
          name: "二手货品转让",
        },
        {
          id: 7,
          name: "二手货品收购",
        },
      ],
      navIndex: "1",
      Info: "",
      parent_id: "",
      partner_val: "",
      uid: "",
      item: "",
    };
  },
  mounted() {
    let uid = window.localStorage.getItem("uid");
    if (uid) {
      this.uid = uid;
      this.getInfo();
    } else {
      this.$message.error("还未登录，请登录后再使用");
      setTimeout(() => {
        this.$router.push({
          path: "/login",
          query: { navid: 10 },
        });
        // let routeData = this.$router.resolve({
        //   path: "/login",
        //   query: { navid: 10 },
        // });
        // window.open(routeData.href, "_blank");
      }, 1500);
    }
  },
  methods: {
    // 判断是否为移动设备
    isMobile() {
      // 获取userAgent字符串
      var userAgent = navigator.userAgent || navigator.vendor || window.opera
 
      // 使用正则表达式来检查userAgent字符串中是否包含某些关键字
      // 这些关键字通常与移动设备相关
      var mobile = /windows phone|iphone|ipad|ipod|android|blackberry|mini|windows ce|palm/i.test(
        userAgent.toLowerCase()
      )
      return mobile;
    },
    quedingxiu() {
      console.log(this.time_frame);
      edit_ref_time_sub({
        id: this.item.id,
        type: this.item.type,
        ref_between_time: this.time_frame[0] + "~" + this.time_frame[1],
      })
        .then((res) => {
          console.log(res);
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getInfo();
          this.dateflag = false;
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.msg);
          this.getInfo();
          this.dateflag = false;
        });
    },
    goto_list() {
      this.dateflag = true;
      this.admendflag = false;
    },
    xiugai(item) {
      this.admendflag = true;
      this.item = item;
    },
    admend() {
      if (this.item.is_meal == 1 && this.item.status != 10) {
        this.$message({
          message: "请联系商务经理后台修改",
          type: "error",
        });
        return;
      }
      this.admendflag = false;
      this.$router.push({
          path: "/edit",
          query: { navid: 10, s_type: this.item.type, id: this.item.id },
        });
      // let routeData = this.$router.resolve({
      //   path: "/edit",
      //   query: { navid: 10, s_type: this.item.type, id: this.item.id },
      // });
      // window.open(routeData.href, "_blank");
    },
    kaiqishop(item) {
      cancel_suspend({
        uid: this.uid,
        send_id: item.id,
        type: item.type,
      })
        .then((res) => {
          console.log(res);
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getInfo();
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.msg);
          this.getInfo();
        });
    },
    zantingshop() {
      let that = this;
      this.$confirm(
        "如需发布，请在个人中心开启恢复，注：购买套餐的客户请联系商务经理暂停其他服务?",
        "温馨提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          console.log("确定");
          ad_suspend({
            uid: that.uid,
            type: that.detaildata.type,
            send_id: that.detaildata.id,
          })
            .then((res) => {
              console.log(res);
              this.$message({
                message: res.msg,
                type: "success",
              });
              that.getInfo();
              that.suspendflag = false;
            })
            .catch((err) => {
              console.log(err);
              this.$message.error(err.msg);
              that.getInfo();
              that.suspendflag = false;
            });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    // 下架
    xiajiashop() {
      let that = this;
      if (that.detaildata.status == 3) {
        this.$message({
          message: "请联系平台或对接您的商务经理做下架或删除处理",
          type: "error",
        });
        return;
      }
      let text = "";
      if (that.detaildata.is_pay == 0) {
        text = "免费信息可直接在上架";
      } else if (that.detaildata.is_pay == 1) {
        text = "付费信息下架后，再上架需重新付费";
      }
      this.$confirm("确定下架，代表本次订单结束，服务结束。" + text, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          take_down({
            uid: that.uid,
            type: that.detaildata.type,
            id: that.detaildata.id,
          })
            .then((res) => {
              console.log(res);
              this.$message({
                message: res.msg,
                type: "success",
              });
              that.getInfo();
              that.suspendflag = false;
            })
            .catch((err) => {
              console.log(err);
              this.$message.error(err.msg);
              that.getInfo();
              that.suspendflag = false;
            });
        })
        .catch(() => {});
    },
    // 下架
    xiajia(item) {
      this.detaildata = item;
      this.suspendflag = true;
    },
    // 上架
    shangjiashop(item) {
      if (item.type == 4) {
        take_up({
          uid: this.uid,
          type: item.type,
          send_id: item.id,
        })
          .then((res) => {
            console.log(res);
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getInfo();
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.msg);
            this.getInfo();
          });
      } else {
        this.$router.push({
          path: "/payment",
          query: { navid: 10, s_type: item.type, id: item.id },
        });
        // let routeData = this.$router.resolve({
        //   path: "/payment",
        //   query: { navid: 10, s_type: item.type, id: item.id },
        // });
        // window.open(routeData.href, "_blank");
      }
    },
    // 我的发布跳转详情
    detailsCut3(item) {
      console.log(item);
      if (item.status == 100) {
        this.$router.push({
          path: "/edit",
          query: { navid: 10, s_type: item.type, id: item.id },
        });
        // let routeData = this.$router.resolve({
        //   path: "/edit",
        //   query: { navid: 10, s_type: item.type, id: item.id },
        // });
        // window.open(routeData.href, "_blank");
      } else {
        if (item.type == 1 || item.type == 2) {
          // this.$router.push({
          //   path: "/transferdetails",
          //   query: { navid: 10, s_type: item.type, id: item.id },
          // });
          let routeData = this.$router.resolve({
            path: "/transferdetails",
            query: { navid: 10, s_type: item.type, id: item.id },
          });
          window.open(routeData.href, "_blank");
        } else if (item.type == 4) {
          // this.$router.push({
          //   path: "/Finddetails",
          //   query: { navid: 10, s_type: item.type, id: item.id },
          // });
          let routeData = this.$router.resolve({
            path: "/Finddetails",
            query: { navid: 10, s_type: item.type, id: item.id },
          });
          window.open(routeData.href, "_blank");
        } else if (item.type == 3 || item.type == 5) {
          let routeData = this.$router.resolve({
            path: "/SecondhandGoods",
            query: { navid: 10, s_type: item.type, id: item.id },
          });
          window.open(routeData.href, "_blank");
        }
      }
    },
    // 收藏跳转详情
    detailsCut2(item) {
      console.log(item);
      if (item.type == 1 || item.type == 2) {
        // this.$router.push({
        //   path: "/transferdetails",
        //   query: { navid: 10, s_type: item.type, id: item.shop_id },
        // });
        let routeData = this.$router.resolve({
          path: "/transferdetails",
          query: { navid: 10, s_type: item.type, id: item.shop_id },
        });
        window.open(routeData.href, "_blank");
      } else if (item.type == 4) {
        // this.$router.push({
        //   path: "/Finddetails",
        //   query: { navid: 10, s_type: item.type, id: item.shop_id },
        // });
        let routeData = this.$router.resolve({
          path: "/Finddetails",
          query: { navid: 10, s_type: item.type, id: item.shop_id },
        });
        window.open(routeData.href, "_blank");
      } else if (item.type == 3 || item.type == 5) {
        let routeData = this.$router.resolve({
          path: "/SecondhandGoods",
          query: { navid: 10, s_type: item.type, id: item.id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    // 接店跳转详情
    detailsCut(item) {
      console.log(item);
      if (item.type == 1 || item.type == 2) {
        // this.$router.push({
        //   path: "/transferdetails",
        //   query: { navid: 10, s_type: item.type, id: item.id },
        // });
        let routeData = this.$router.resolve({
          path: "/transferdetails",
          query: { navid: 10, s_type: item.type, id: item.id },
        });
        window.open(routeData.href, "_blank");
      } else if (item.type == 4) {
        // this.$router.push({
        //   path: "/Finddetails",
        //   query: { navid: 10, s_type: item.type, id: item.id },
        // });
        let routeData = this.$router.resolve({
          path: "/Finddetails",
          query: { navid: 10, s_type: item.type, id: item.id },
        });
        window.open(routeData.href, "_blank");
      } else if (item.type == 3 || item.type == 5) {
        let routeData = this.$router.resolve({
          path: "/SecondhandGoods",
          query: { navid: 10, s_type: item.type, id: item.id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    cutagents() {
      if (this.partnerInfo.apply_val.status == 1) {
        this.$message("正在审核中，请耐心等待噢~");
      } else if (this.partnerInfo.apply_val.status == 2) {
        this.$message.success("审核已通过~");
      } else if (this.partnerInfo.apply_val.status == 3) {
        this.$router.push({
          path: "/Cityagent",
          query: { navid: 10, apply_id: this.partnerInfo.apply_val.id },
        });
        // let routeData = this.$router.resolve({
        //   path: "/Cityagent",
        //   query: { navid: 10, apply_id: this.partnerInfo.apply_val.id },
        // });
        // window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          path: "/Cityagent",
          query: { navid: 10 },
        });
        // let routeData = this.$router.resolve({
        //   path: "/Cityagent",
        //   query: { navid: 10 },
        // });
        // window.open(routeData.href, "_blank");
      }
    },
    cutpartner() {
      if (this.partnerInfo.apply_val.status == 1) {
        this.$message("正在审核中，请耐心等待噢~");
      } else if (this.partnerInfo.apply_val.status == 2) {
        this.$message.success("审核已通过~");
      } else if (this.partnerInfo.apply_val.status == 3) {
        this.$router.push({
          path: "/CityPartners",
          query: { navid: 10, apply_id: this.partnerInfo.apply_val.id },
        });
        // let routeData = this.$router.resolve({
        //   path: "/CityPartners",
        //   query: { navid: 10, apply_id: this.partnerInfo.apply_val.id },
        // });
        // window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          path: "/CityPartners",
          query: { navid: 10 },
        });
        // let routeData = this.$router.resolve({
        //   path: "/CityPartners",
        //   query: { navid: 10 },
        // });
        // window.open(routeData.href, "_blank");
      }
    },
    cutbusiness(id, status) {
      if (id) {
        if (status == 1) {
          this.$message("正在审核中，请耐心等待噢~");
          return;
        } else if (status == 2) {
          this.$message.success("审核已通过，去微信小程序商家登录看看吧~");
          setTimeout(() => {
            this.show = true;
          }, 3000);
          return;
        } else if (status == 3) {
          this.$router.push({
            path: "/BusinessEntryApplication",
            query: { navid: 10, apply_id: id },
          });
          // let routeData = this.$router.resolve({
          //   path: "/BusinessEntryApplication",
          //   query: { navid: 10, apply_id: id },
          // });
          // window.open(routeData.href, "_blank");
        }
      } else {
        this.$router.push({
          path: "/BusinessEntryApplication",
          query: { navid: 10 },
        });
        // let routeData = this.$router.resolve({
        //   path: "/BusinessEntryApplication",
        //   query: { navid: 10 },
        // });
        // window.open(routeData.href, "_blank");
      }
    },
    // 确认转
    handleEdit(index, row) {
      console.log(index, row);
      let that = this;
      transfer_out({
        uid: that.uid,
        type: that.s_type,
        id: that.id,
        to_uid: row.id,
      })
        .then((res) => {
          console.log(res);
          this.$message({
            message: res.msg,
            type: "success",
          });
          that.dialogTableVisible = false;
          that.getInfo();
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.msg);
          that.dialogTableVisible = false;
          that.getInfo();
        });
    },
    // 确认转
    Confirmtr(id, type) {
      let that = this;
      that.s_type = type;
      that.id = id;
      been_the_store({
        type: type,
        id: id,
      })
        .then((res) => {
          console.log(res);
          that.gridData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      that.dialogTableVisible = true;
    },
    // 取消发布
    CancelRelease(id, type) {
      let that = this;
      this.$confirm("是否要取消发布，取消后无法恢复?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          cancel_send({
            uid: that.uid,
            type: type,
            id: id,
          })
            .then((res) => {
              console.log(res);
              this.$message({
                message: res.msg,
                type: "success",
              });
              that.getInfo();
            })
            .catch((err) => {
              console.log(err);
              this.$message.error(err.msg);
              that.getInfo();
            });
        })
        .catch(() => {});
    },
    CancelShop(id, type) {
      let that = this;
      this.$confirm("是否要取消接店?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          cancel_take({
            uid: that.uid,
            type: type,
            id: id,
          })
            .then((res) => {
              console.log(res);
              this.$message({
                message: res.msg,
                type: "success",
              });
              that.getInfo();
            })
            .catch((err) => {
              console.log(err);
              this.$message.error(err.msg);
              that.getInfo();
            });
        })
        .catch(() => {});
    },
    CancelCollect(id, type) {
      let that = this;
      this.$confirm("是否要取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del_collect({
            uid: that.uid,
            type: type,
            id: id,
          })
            .then((res) => {
              console.log(res);
              this.$message({
                message: res.msg,
                type: "success",
              });
              that.getInfo();
            })
            .catch((err) => {
              console.log(err);
              this.$message.error(err.msg);
              that.getInfo();
            });
        })
        .catch(() => {});
    },
    // 发布
    release() {
      // this.$router.push({
      //   path: "/release",
      //   query: { navid: 10 },
      // });
      let routeData = this.$router.resolve({
        path: "/release",
        query: { navid: 10 },
      });
      window.open(routeData.href, "_blank");
    },
    //首页导航旺铺切换
    WinportClick(id) {
      this.type = id;
      this.getInfo();
    },
    getInfo() {
      let that = this;
      if (that.navIndex == 1) {
        user_core({
          uid: that.uid,
        })
          .then((res) => {
            console.log(res);
            that.Info = res.data;
            window.localStorage.setItem("user_type", res.data.user_type);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.navIndex == 2) {
        my_send({
          uid: that.uid,
          classify: that.type,
        })
          .then((res) => {
            console.log(res);
            let arr = [];
            res.data.forEach((item) => {
              item.forEach((items) => {
                arr.push(JSON.parse(JSON.stringify(items)));
              });
            });
            this.list = arr;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.navIndex == 3) {
        meet_shop_list({
          uid: that.uid,
        })
          .then((res) => {
            console.log(res);
            let arr = [];
            res.data.forEach((item) => {
              item.forEach((items) => {
                arr.push(JSON.parse(JSON.stringify(items)));
              });
            });
            this.list = arr;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.navIndex == 4) {
        user_collect({
          uid: that.uid,
        })
          .then((res) => {
            console.log(res);
            this.list = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.navIndex == 5) {
        my_apply({
          uid: that.uid,
        })
          .then((res) => {
            console.log(res);
            this.list = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.navIndex == 6) {
        city_partner({
          uid: that.uid,
        })
          .then((res) => {
            console.log(res);
            res.data.val = res.data.val
              .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
              .replace(/<section/g, "<div")
              .replace(/\/section>/g, "div>");
            this.partnerInfo = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.navIndex == 7) {
        city_agents({
          uid: that.uid,
        })
          .then((res) => {
            console.log(res);
            res.data.val = res.data.val
              .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
              .replace(/<section/g, "<div")
              .replace(/\/section>/g, "div>");
            this.partnerInfo = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    select(e) {
      this.navIndex = e;
      this.getInfo();
    },
  },
};
</script>

<style lang="scss">
// .bg {
//   background-image: url(../../assets/img/bg.png);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
// }

.el-input.is-disabled .el-input__inner {
  background-color: #ffffff !important;
  border-color: #d2d2d2 !important;
  color: #222222 !important;
  font-size: 0.875rem !important;
  cursor: not-allowed;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 1.75rem;
  color: #8c939d;
  width: 11.125rem;
  height: 11.125rem;
  line-height: 11.125rem;
  text-align: center;
}

.avatar {
  width: 11.125rem;
  height: 11.125rem;
  display: block;
}
.member {
  .el-input {
    width: auto !important;
  }
}

.el-menu-item {
  height: 3.5rem;
  line-height: 3.5rem;
  font-size: 0.875rem;
}
.el-form-item{
  margin-bottom: 0.375rem;
  .el-form-item__label{
    font-size: 0.875rem;
    line-height: 2.5rem;
    padding: 0 0.75rem 0 0;
  }
  .el-form-item__content{
    line-height: 2.5rem;
    .el-input__inner{
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 2.5rem;
    }
  }
}
.el-tag{
  height: 2rem;
  padding: 0 0.625rem;
  line-height: 0.875rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.el-button--mini{
  font-size: 0.75rem;
  padding: 0rem 0.9375rem;
  line-height: 1.875rem;
}

</style>
